.App {
  width: 100%;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
.carousel-inner {
  margin-bottom: 150px;
}

.section {
  height: 100%;
}

.content {
  max-width: 1440px !important;
  width: 100%;
}

.sub-hdr-text {
  font-size: 28px;
  color: #5d4dc4;
  margin-bottom: 3rem;
}
.hdr-text {
  font-size: 80px;
  text-align: center;
  color: #5d4dc4;
  margin: 50px;
}

/* crowd investment  */
.crowd-vd {
  /* width: 80%; */
  height: 60%;
  width: auto;
}

/* Buttons group styles */
.buttonGrp {
  width: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 100px;
}

.buttonGrp > * {
  margin: 20px;
}
.oelbtn:hover {
  background-color: #bcbec0 !important;
}

/* inverting colors */

#hero .oelbtn:hover {
  background-color: #5d4dc4 !important;
  color: #fde3ca !important;
}

#videosection .oelbtn:hover {
  background-color: #fde3ca !important;
  color: #5d4dc4 !important;
}

#whatwedo .oelbtn:hover {
  background-color: #5d4dc4 !important;
  color: #fea90e !important;
}
#le1section .oelbtn:hover {
}
#partners .oelbtn:hover {
  background-color: #5d4dc4 !important;
  color: #fde3ca !important;
}

.oelbtn {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

/* Introducing our first product */
#le1-intro-col2 {
}

/* footer  */
.footer-lft p {
  margin: 0;
}

#usef-links a {
  /* text-decoration: ; */
  color: #fff !important;
}

#button2-vid-sect {
  margin: 50px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Hero section */

  .oelbtn {
    width: 150px;
  }

  /* footer  */
  .footer-lft {
    padding-bottom: 35% !important;
  }

  .footer-lft #ft-links {
    width: 100% !important;
    padding: 2rem;
  }

  #ft-copyright {
    padding: 2rem;
  }

  /* Buttons group styles */
  .buttonGrp > * {
    margin: 10px;
    width: 100% !important;
  }
  .buttonGrp {
    margin-top: 0px !important;
    /* flex-direction: column-reverse; */
  }
  .buttonGrp:nth-child(1) {
    order: 2;
  }
  .buttonGrp:nth-child(2) {
    order: 1;
  }

  /* Introducing our first product */
  #le1-intro-col2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .service-item {
    max-width: 100% !important;
  }

  .hdr-text {
    font-size: 3em;
    text-align: center;
    margin: 10px;
  }

  .sub-hdr-text {
    font-size: 1em;
    text-align: center;
    margin: 10px;
  }
  .section {
    height: 100%;
  }

  /* video section */
  #button1-vid-sect {
    margin-bottom: 70px !important;
  }
  #button2-vid-sect {
    margin-bottom: 70px !important;
    margin-top: 0px;
  }
  #video-section-title {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  #videosection .content {
    padding: 0px !important;
  }

  #journey-vid-sect {
    order: 2;
  }
  #video-vid-sect {
    order: 1;
  }

  /* what we do section */
  .arrow-wwd-sec {
  }

  #whatwedo .content {
    padding: 30px !important;
  }
}

@media only screen and (max-width: 768px) {
  /* what we do section */
  .arrow-wwd-sec {
    display: none !important;
  }
}

@media only screen and (max-width: 850px) {
  /* what we do section */

  /* video section  */

  #videoJourneyWrapper {
    width: 100% !important;
  }

  #journey-vid-sect {
    width: 80% !important;
    /* grid-column-start: 2;
    grid-column-end: 3; */
  }

  /* Le1 */
  #le1section {
    display: none;
  }
}
