#ftr-title {
  text-align: center;
  font-size: 20px;
  margin: 25px 0 50px 0;
  color: #5d4dc4;
  font-family: "Gellix Medium";
}
#ftr-map {
  width: 100%;
  height: 300px;
  /* margin: 20px 0px; */
}
/* #mapStyle {
  width: 50%;
  height: 50%;
} */
#ftr-address {
  text-align: center;
  color: #5d4dc4;
  font-size: 12px;
  margin: 25px 0;
}
#ftr-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}
#ftr-socials p {
  font-size: 12px;
  margin: 0;
  color: #5d4dc4;
}
#ftr-socials a {
  margin: 0 2.5px;
}

#ftr-copyright {
}
#ftr-logo-and-socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#ftr-cta-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 20px 0px;
  padding: 0px;
}
#ftr-cta-links p {
  font-size: 12px;
  margin: 0;
  color: #5d4dc4;
}
#ftr-copyright {
  background-color: #5d4dc4;
  width: 100%;
  padding: 10px;
}
#ftr-copyright p {
  font-size: 12px;
  margin: 0px;
  text-align: center;
  color: #fff;
}

#ftr-hr {
  margin: 5px 0;
}

#ftr-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ftr-logo {
  margin: 20px 0;
}

@media only screen and (min-width: 600px) {
  #ftr-map {
    max-width: 550px;
    max-height: 550px;
    height: 550px;
    /* margin: 20px 0px; ,,*/
  }
  #ftr-logo-and-socials-container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 50px;
  }
  #ftr-socials {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
  }
  #ftr-hr {
    display: none;
  }
  #ftr-hr-cont {
    display: none;
  }

  #ftr-cta-links {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  #ftr-address {
    width: 100%;
  }
  #ftr-logo {
    width: 100%;
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
