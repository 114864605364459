#le1-intro-text {
  height: 100%;
}
#le1-bracketed-text {
  margin-top: 20px;
  margin-bottom: 20px;
}
#le1-product-name {
  font-size: 8rem;
  /* margin-top: 50px; */
}
#le1-action-buttons .oelbtn {
  font-size: 12px;
  width: 140px;
  margin-bottom: 50px;
}
#le1-action-buttons .oelbtn:hover {
  color: #5d4dc4 !important;
  background-color: #ffffff !important;
}
#le1-action-buttons a#oel-btn-container {
  margin: 8px;
}
#le1-action-buttons {
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
  width: 80%;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
#le1-desc-text {
  font-size: 12px;
  width: 80%;
}
#le1-img-cont {
  display: none;
}
#le1-main-wrapper {
  padding: 40px 0;
}
#le1-copy-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 85%;
}

@media only screen and (min-width: 600px) {
  #le1-copy-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 95%;
    /* background-color: aqua; */
  }
  #le1-intro-text {
    height: 100%;
    width: 100%;
  }
  #le1-img-cont {
    display: block;
    /* max-height: 800px; */
    /* width: 50%;  */
    height: 50%;
    overflow: hidden;
  }
  #le1-copy-wrapper {
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    grid-template-rows: min-content;
    width: 100%;
  }
  #le1-main-wrapper {
    padding: 30px 0;
  }
  #le1-product-name {
    font-size: 4rem;
    /* margin-top: 50px; */
  }
}
/* height: auto;
    width: 100%;
    position: relative;
    left: -100px; */
