.ptn-partners-bunch {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: 10px;
  width: 100%;
  max-width: 100%;
  justify-items: center;
  row-gap: 30px;
  align-items: center;
}
#partners-mobile {
  height: 350px;
  width: 100%;
}
#partners-mobile .carousel-indicators li {
  background-color: #7166cf !important;
}
#ptn-partners-title {
  font-size: 20px;
  text-align: center;
  color: #7166cf;
  margin: 50px 0px 65px 0px;
  font-family: "Gellix Medium";
}
.ptn-logo {
  width: auto;
  height: 30px;
}
#ptn-bottom-text-and-btn p {
  text-align: center;
  font-size: 14px;
  color: #7166cf;
}
#ptn-bottom-text-and-btn .oelbtn {
  font-size: 12px;
}
#ptn-bottom-text-and-btn .oelbtn:hover {
  background-color: #fff !important;
  color: #5d4dc4 !important;
}
#ptn-web {
  display: none;
}

@media only screen and (min-width: 400px) {
  .ptn-partners-bunch {
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
  }
}
@media only screen and (min-width: 600px) {
  #caption-text-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #caption-text {
    max-width: 50%;
  }
  #partners-mobile {
    display: none;
  }
  #ptn-web {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1024px;
  }
  /* component */
  #partnerLogos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    width: 100%;
    gap: 40px;
    justify-items: center;
    align-items: center;
    overflow: hidden;

    /* gridTemplateColumns: "",
    width: "100%",
    maxWidth: "1118px",
    marginTop: "2rem",
    justifyItems: "start",
    alignItems: "center",
    // columnGap: 20,
    gridGap: 50, */
  }
  #partnerLogos a {
  }
}
