/* Top navigation bar */
.em-top-nav {
  color: #7166cf;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  padding: 10px 0;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.em-tn-title {
  text-align: center;
  /* padding-left: 32px; */
  font-size: 25px;
  font-family: "Gellix Medium";
}

.em-top-nav-menu-button {
  align-self: flex-end;
  /* font-size: 30px; */
  margin: 0px 15px 0 0;
  /* line-height: 15px; */
  display: flex;
  align-items: center;
  height: 100%;
}

#em-menu-btns .oelbtn {
  display: none;
}
#em-menu-btns .oelbtn:hover {
  color: #ffffff !important;
  background-color: #7166cf !important;
}
#em-menu-btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

/* pop up menu styles */
.pp-menu-title {
  text-align: center;
  margin-left: 30px;
  font-family: "Gellix Medium";
}
#mobile-close-btn {
  text-align: center;
  color: #fff;
}
#pp-menu-logo {
  margin: 20px;
}
#pp-menu-list p {
  margin: 0;
  font-size: 19px;
  text-align: center;
  color: #fff;
}
#pp-menu-copyright p {
  text-align: center;
  margin: 10px;
  color: #fff;
  font-size: 13px;
}
#pp-menu-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  width: 100%;
  height: 100%;
}

.pp-menu-top-nav {
  color: #7166cf;
  padding: 10px 0;
  background-color: #ffffff;
  width: 100%;
}
.pp-menu-child ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

#pp-menu-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pp-menu-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.pp-menu-title {
  display: none;
}
.pp-menu-top-nav .em-top-nav-menu-button {
  display: none;
}
#pp-menu-copyright {
  display: none;
}
#pp-menu-logo {
  display: none;
}

/* large screens */
@media only screen and (min-width: 600px) {
  /* hover underline component */
  .hu-link-underline {
    position: relative;
    padding-bottom: 4px;
  }
  .hu-underline-hover {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 3px;
  }

  .hu-link-underline a {
    color: inherit;
    text-decoration: none;
  }
  .hu-link-underline p {
    margin: 0;
  }
  .hu-link-underline a:hover {
    color: inherit;
    text-decoration: none;
  }
  /* pop up menu styles */
  .pp-menu-top-nav {
    color: #7166cf;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 10px 0;
    background-color: #ffffff;
    width: 100%;
  }
  #mobile-close-btn {
    display: none;
  }
  .pp-menu-item-saparator {
    display: none;
  }
  .pp-menu-title {
    display: block;
  }
  .pp-menu-top-nav .em-top-nav-menu-button {
    display: flex;
  }
  #pp-menu-copyright {
    display: block;
  }
  #pp-menu-logo {
    /* display: block; */
    display: flex;
    justify-content: center;
  }
  /* top navigation  */
  .em-top-nav {
    justify-items: center;
    align-items: center;
  }
  .em-top-nav-menu-button {
    display: flex;
    align-items: center;
    height: 100%;
  }
  #em-menu-btns {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: flex-end;
    justify-items: flex-end;
    width: 200px;
    column-gap: 20px;
    width: 100%;
  }
  #em-menu-btns .oelbtn {
    display: block;
    font-size: 12px;
    width: 120px;
  }
  #em-tn-title-cnt {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .em-tn-title {
    font-size: 2rem;
  }
}

/* large screens */
@media only screen and (min-width: 768px) {
  /* #spacer; */
}
