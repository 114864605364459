@font-face {
  font-family: "Gellix Medium";
  src: local("Gellix Medium"), url("../fonts/Gellix-Medium.woff2"),
    url("../fonts/Gellix-Medium.woff"), url("../fonts/Gellix-Medium.eot");
}
#hero-contn {
  height: 90vh;
}
#hr-main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content max-content;
}
#hr-img-sec {
  background: url("../media/mbLabBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
#hr-img-sec-inner {
  width: 80%;
  height: auto;

  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
  margin-top: 50px; */
}

#leftbrkt {
  height: 50%;
}
#rightbrkt {
  height: 50%;
}

#hr-img-sec-inner p {
  font-size: 1.5rem;
  color: #fff;
  margin: 10px;
  /* font-weight: 700; */
  font-family: "Gellix Medium";
}
#hr-logobtn-sec {
  margin: 0px;
  background: #5d4dc4;
}

#hr-logobtn-sec .oelbtn {
  width: 98px;
  height: 35px;
  font-size: 12px;
}

#hr-logobtn-sec-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 20px;
}

#actn-btns-ls {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  width: 100%;
  /* margin: 10px; */
}
#actn-btns-ls li {
  margin: 0 10px;
}

#hr-logo {
  margin: 20px;
}

#oel-btn-container {
}
.hr-btm-address {
  background: #aea6e1;
}

.hr-btm-address p {
  text-align: center;
  margin: 10px;
  font-size: small;
  color: #7166cf;
}
.hr-btm-address br {
  display: none;
}

#hr-aside {
  display: none;
}

#hr-labimage-bg {
  display: none;
}
#hr-addr-lg {
  display: none;
}
#brckt-content {
  /* TODO: move to component styles */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
  /* calc(100vw * (2.5 / 100)); */
  #hr-img-sec-inner p {
    font-size: 1.8rem;
  }
  #hr-logobtn-sec .oelbtn:hover {
    color: #7166cf !important;
    background-color: #fff !important;
  }
  #hr-logobtn-sec .oelbtn {
    width: 120px;
  }
  #hr-addr-lg {
    display: block;
  }
  #hr-labimage-bg {
    display: block;
    opacity: 0.5;
  }
  #hr-logobtn-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100%; */
  }
  #hr-logobtn-sec-inner {
    width: 30%;
    justify-content: unset;
  }
  #hr-img-sec-inner {
    /* width: 50%; */
    /* max-width: 30vw; */
    position: absolute;
  }

  .hr-btm-address p {
    margin: 0;
    padding: 10px;
  }
  #hr-main .hr-btm-address {
    display: none;
  }
  #hr-main {
    display: grid;
    flex-direction: column;
    background-color: #5d4dc4;
    justify-items: center;
    align-items: center;
  }
  #hero-contn {
    height: 100%;
    display: grid;
    grid-template-columns: min-content 1fr;
    padding-top: 58px;
  }
  #hr-img-sec {
    background: #5d4dc4;
    position: relative;
    width: 90%;
    padding: 100px 0 0 0;
  }
  #hr-logo-cnt {
    display: none;
  }
  #hr-aside {
    display: grid;
    background-color: #5d4dc4;
    width: 320px;
    grid-template-rows: max-content;
  }
  #hr-notice {
    width: 100%;
    position: absolute;
    top: 0;
  }
  #hr-notice p {
    background-color: #b4b5b4;
    color: #7166cf !important;
    padding: 20px 0;
    text-align: center;
    margin: 0;
  }

  #hr-aside-video {
    background: #ff7f48;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
    position: relative;
  }
  #hr-aside-video p {
    text-align: center;
    color: #a73200;
  }
  #hr-hrnt-cont {
    width: 100%;
  }
  #hr-hrnt-cont hr {
    border: 0.75px solid #ff8a58;
  }
  #hr-vid-comp {
    margin: 20px 0;
  }
  #hr-cube-logo {
    width: auto;
    height: 50px;
    margin: 20px 0;
  }
  #hr-aside-logo-cont {
    background: #fff;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #hr-labimage-bg {
    width: 100%;
    height: auto;
    margin: 10px;
    /* max-height: 45vh; */
  }
  #actn-btns-ls li {
    margin: 20px 10px;
  }

  /* .hr-link-underline {
    position: relative;
    padding-bottom: 4px;
  }
  .hr-underline-hover {
    position: absolute;
    border-bottom: #5d4dc4 1px solid;
    width: 100%;
    left: 0;
    bottom: 3px;
  }

  .hr-link-underline a {
    color: inherit;
    text-decoration: none;
  }
  .hr-link-underline a:hover {
    color: inherit;
    text-decoration: none;
  } */
}

/* @media only screen and (min-width: 1200px) {
  #hr-img-sec {
    background: #5d4dc4;
    position: relative;
    width: 56%;
  }
} */

@media only screen and (max-width: 768px) {
  #hr-aside {
    width: 250px;
  }
  #hr-aside-video {
    padding: 0 10px;
    padding-top: 88px;
  }
  #hr-img-sec-inner p {
    font-size: 1.5rem;
  }
}
