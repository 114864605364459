#updates {
  background-image: url("../media/videosectionbg.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}

#vs-background {
  width: 100%;
  height: 500px;
  /* background-color: #ff8a58; */
}
#vs-video-wrapper {
  width: 80%;
}
#vs-content-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #a73200;
  /* position: absolute; */
}
#vs-title {
  margin: 20px;
}
#vs-actn-btn {
  margin: 30px;
}
#vs-actn-btn .oelbtn {
  font-size: 12px;
}
#vs-actn-btn .oelbtn:hover {
  font-size: 12px;
  color: #942202 !important;
  background-color: rgb(255, 127, 72) !important;
}
#vs-content-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
#vl {
  display: none;
}

@media only screen and (min-width: 600px) {
  #vs-content-wrapper {
    max-width: 820px;
    margin: 8% 0;
  }
  #vs-content-inner-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    justify-items: center;
    align-items: center;
    /* padding-bottom: 20px; */
  }

  #vs-video-wrapper p {
    font-size: 12px;
    margin: 20px;
  }
  #vs-video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  #vs-btn-copy-wrapper {
    width: 70%;
  }
  #vl {
    display: block;
    border-left: 1px solid #ff8a58;
    height: 100%;
  }
  #vs-hr {
    margin: 0 !important;
  }
  #vs-actn-btn .oelbtn {
    width: 140px;
  }
}
