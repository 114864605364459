.mc-field-group .form-control {
  background-color: #9e3619;
  height: 50px;
  border: none;
  margin: 5px 0;
  color: #ff9359;
  text-align: center;
  font-size: 13px;
}
.mc-field-group .form-control::placeholder {
  color: #ff9359;
  text-align: center;
  font-size: 13px;
}
/* input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  background-color: #9e3619 !important;
  color: #ff9359 !important;
  text-align: center !important;
  font-size: 13px !important;
} */
.mc-field-group .form-control:focus {
  color: #ff9359;
  background-color: #9e3619;
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ff9359;
}

.mc-field-group label {
  display: none;
}
#nl-content-wrapper {
  background-color: #942202;
  width: 80%;
  margin: 35px 0px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
#newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../media/newsletterbg.png");
  background-size: cover;
}
#mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0 30px;
}
#nl-title {
  color: #ff9359;
  margin: 20px 0;
  text-align: center;
}

#nl-img-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-group {
  margin: 25px 0;
  align-items: center;
  flex-direction: column;
  width: unset !important;
}

.input-group ul {
  margin: 0;
  padding: 0;
}
.input-group label {
  margin: 0;
  color: #ff9359;
  font-size: 13px;
}
.input-group input {
  color: #942202 !important;
  background-color: #9e3619 !important;
}
.nl-hr {
  border-color: #9e3619;
  border-width: 0.7px;
}
.nl-hr-container {
  width: 100%;
  position: relative;
}
#mc_embed_signup {
  width: 100%;
}

#form-policy {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#form-policy p {
  margin: 0;
  text-align: center;
  font-size: 13px;
  color: #ff9359;
  width: 100%;
}
/* #policy-link {
  border-bottom: 1px solid #ff9359;
}
#policy-link:hover {
  position: relative;
  padding-bottom: 2px;
} */
.clear {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}
.clear .btn {
  width: 150px;
  color: #942202;
  background-color: #ff9359;
  border-radius: 30px;
  border: #942202 1px solid;
  font-size: 13px;
}
.clear .btn:hover {
  color: #ff9359;
  background-color: #942202;
  border: #ff9359 1px solid;
}
.clear .btn:focus {
  color: #ff9359 !important;
  background-color: #942202 !important;
  border: #ff9359 1px solid !important;
  box-shadow: 0 0 0 0.2rem rgb(255, 126, 72);
}
.clear .btn:active {
  color: #ff9359 !important;
  background-color: #942202 !important;
  border: #ff9359 1px solid !important;
}
#nl-image {
  height: auto;
  width: 50vw;
}
#form-radio {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  #nl-content-wrapper .nl-hr-container .nl-hr {
    margin: 0;
  }
  #mc_embed_signup {
    border-left: #9e3619 0.7px solid;
  }
  #form-policy {
    margin-bottom: 20px;
  }
  #mc_embed_signup_scroll {
    display: block;
    padding: 0 50px;
  }
  #mc_form_inner .nl-hr-container {
    display: none;
  }
  #form-radio {
    display: flex;
    align-items: center;
  }

  #nl-image-form-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    width: 100%;
    justify-items: center;
    align-items: center;
  }
  #nl-hr-container {
    display: none;
  }
  #nl-image {
    height: auto;
    width: 30vw;
  }
  #nl-img-cont {
    margin: 20px;
  }
}
