@font-face {
  font-family: "Gellix Regular";
  src: local("Gellix Regular"), url("./fonts/Gellix-Regular.woff2"),
    url("./fonts/Gellix-Regular.woff"), url("./fonts/Gellix-Regular.ttf");
}
@font-face {
  font-family: "Gellix Medium";
  src: local("Gellix Medium"), url("./fonts/Gellix-Medium.woff2"),
    url("./fonts/Gellix-Medium.woff"), url("./fonts/Gellix-Medium.eot");
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: "Gellix Regular";
  font-feature-settings: "ss02", "ss09", "ss04", "ss11", "ss12", "ss13";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fde3ca;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* LAYOUT */

.lt-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-glyph {
  font-feature-settings: "ss02", "ss09", "ss04", "ss11", "ss12", "ss13";
}
